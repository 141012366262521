<template>
  <v-dialog
    v-model="showDialog"
    max-width="800"
  >
    <v-card
      height="510"
    >
      <v-card-title>
        Dados da chamada
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-row dense>
            <v-col cols="4">
              <v-text-field
                  label="Protocolo"
                  outlined
                  name="protocol"
                  v-model="call.protocol"
                  readonly
                ></v-text-field>
            </v-col>
            <v-col cols="8">
              <v-text-field
                  label="Cliente"
                  outlined
                  name="client"
                  v-model="call.client_name"
                  readonly
                ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="4">
              <v-text-field
                  label="CPF/CNPJ"
                  outlined
                  name="document"
                  v-model="call.cpf_cnpj"
                  readonly
                ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                  :items="call_motives"
                  item-text="description"
                  item-value="description"
                  label="Motivo da ligação"
                  outlined
                  name="call_motive"
                  v-model="call.call_motive"
                ></v-select>
            </v-col>
            <v-col cols="4">
              <v-text-field
                  label="Outras informações"
                  outlined
                  name="other_infos"
                  v-model="call.client_extra_info"
                  readonly
                ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-textarea
                  label="Observação"
                  rows="5"
                  outlined
                  name="obs"
                  v-model="call.call_obs"
                ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="hide()">Fechar</v-btn>
        <v-btn color="blue darken-1" text @click="save()">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import  { APIFactory } from '../api/factory'
const CallApi = APIFactory.get('call')
export default {
  props: {
    showDialog: Boolean,
    call: Object,
    call_motives: []
  },
  methods: {
    hide: function() {
      this.showDialog = false
      this.$emit("onHide");
    },
    save: function() {
      let data = {
        call_motive: this.call.call_motive,
        obs: this.call.call_obs
      }

      CallApi.update(this.call.id, data).then(response => {
        if (response.status == 200){
          this.hide()
        }
      })
    }
  }
}
</script>