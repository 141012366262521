import base from "./base";

const resource = "/agents";

export default {
  my_queues() {
    return base.get(`${resource}/my_queues`);
  },
  my_extensions() {
    return base.get(`${resource}/my_extensions`);
  },
  loginQueue(data) {
    return base.post(`${resource}`, data);
  },
  logoutQueue(id) {
    return base.delete(`${resource}/${id}`);
  },
  resume() {
    return base.get(`${resource}/resume`);
  },
  details() {
    return base.get(`${resource}/details`);
  },
  last_calls() {
    return base.get(`${resource}/last_calls`);
  },
  current_call() {
    return base.get(`${resource}/current_call`);
  },
  change_queue(data) {
    return base.post(`${resource}/change_queue`, data);
  },
  pause(data) {
    return base.post(`${resource}/pause`, data);
  },
  pauseTime() {
    return base.get(`${resource}/pause_time`);
  },
  schedule_pause(data) {
    return base.post(`${resource}/schedule_pause`, data);
  },
  evaluates() {
    return base.get(`${resource}/evaluates`);
  },
};
