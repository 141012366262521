<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon color="indigo" dark v-bind="attrs" v-on="on">
          <v-avatar>
            <img :src="require('@/assets/suporte1.png')" alt="Agente" />
          </v-avatar>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <img :src="require('@/assets/suporte1.png')" alt="Agente" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ user.name }}</v-list-item-title>
              <v-list-item-subtitle>
                Ramal {{ user.extension }}
                <br />
                Filas:
                <span v-for="queue in user.queues" :key="queue">
                  {{ queue }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item-group>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-poll</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title @click="openResume()"
                  >Resumo</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>

          <v-list-item-group>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-arrow-left-right</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title @click="openQueueModal = true"
                  >Trocar de fila</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <v-card-actions>
          <v-btn icon @click="logout">
            <v-icon>mdi-exit-to-app</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <QueueModal :showDialog="openQueueModal" @onHide="openQueueModal = false" />
    <ResumeModal
      :showDialog="openResumeModal"
      :overlay="overlay"
      @onHide="openResumeModal = false"
    />
  </div>
</template>

<script>
import QueueModal from "./QueueModal";
import ResumeModal from "./ResumeModal";
import { APIFactory } from "../api/factory";

const AuthApi = APIFactory.get("auth");
const AgentApi = APIFactory.get("agent");

export default {
  data: () => ({
    overlay: true,
    fav: true,
    menu: false,
    message: false,
    hints: true,
    openResumeModal: false,
    openQueueModal: false,
  }),
  components: {
    QueueModal,
    ResumeModal,
  },
  methods: {
    openResume: function() {
      this.overlay = true;
      this.openResumeModal = true;
      AgentApi.resume().then((response) => {
        this.$store.dispatch("agent/resumeData", response);
        AgentApi.details().then((response) => {
          this.$store.dispatch("agent/detailsData", response);
          this.overlay = false;
        });
      });
    },
    logout: function() {
      AgentApi.logoutQueue(this.user.id).then((response) => {
        if (response.status == 200) {
          AuthApi.logout().then((response) => {
            if (response.status === 204) {
              this.$store.dispatch("user/doLogout");
            }
          });
        }
      });
    },
  },
  computed: {
    user: function() {
      return this.$store.getters["user/currentUser"];
    },
  },
};
</script>
