import axios from "axios"

const baseDomain = process.env.VUE_APP_AURORA;
//const baseDomain = "http://161.35.234.157:3000";
const baseUrl = `${baseDomain}/api/v1`;

const axioAurora = axios.create({
  baseURL: baseUrl,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json'
  }
})

export default {
  sendSMS(token, campaign, number, text) {
    axioAurora.defaults.headers.common["access-token"] = token;
    let data = {
      messages: [
        { number: number, message: text },
      ]
    }
    return axioAurora.post(`/external/campaigns/${campaign}/sms`, data);
  }
}