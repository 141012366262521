<template>
  <v-container fluid>
    <v-row>
      <v-col xs="12" sm="12" md="12">
        <CurrentCall :call_motives="call_motives" />
      </v-col>
    </v-row>
    <v-row v-if="user.aurora_active === true">
      <v-col xs="12" sm="12" md="12"> <SendSMS /> </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <LastCall :call_motives="call_motives" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { APIFactory } from "../api/factory";
import CurrentCall from "../components/attendence/CurrentCall";
import SendSMS from "../components/attendence/SendSMS";
import LastCall from "../components/attendence/LastCall";
import { EventHub } from "../utils/eventHub";

const ListApi = APIFactory.get("list");

export default {
  data: () => ({
    call_motives: [],
  }),
  components: {
    CurrentCall,
    LastCall,
    SendSMS,
  },
  created: function () {
    this.loadData();
    this.connect();
  },
  methods: {
    connect: function () {
      var self = this;
      let user = this.user;
      self.ws = new WebSocket(process.env.VUE_APP_WS + "/" + user.code);
      self.ws.onopen = function () {
        console.log("Conectado");
      };
      self.ws.onerror = function () {
        console.log("Não foi possível conectar-se ao servidor");
      };
      self.ws.onmessage = function (e) {
        EventHub.$emit("refresh-data", e);
      };
    },
    loadData: function () {
      ListApi.call_motives().then((response) => {
        this.call_motives = response.data.call_motives;
      });
    },
  },
  computed: {
    user: function () {
      return this.$store.getters["user/currentUser"];
    },
  },
};
</script>