import axios from "axios"

const baseDomain = process.env.VUE_APP_API;
//const baseDomain = "http://161.35.234.157:3000";
const baseUrl = `${baseDomain}/api/v1`;

export default axios.create({
  baseURL: baseUrl,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json'
  }
})