<template>
  <v-sheet class="px-4" rounded color="#F2F2F2">
    <v-row>
      <v-col xs="12" sm="12" md="12">
        <v-card height="150">
          <v-card-title> Enviar SMS </v-card-title>
          <v-card-text>
            <v-form>
              <v-row dense>
                <v-col cols="2">
                  <v-text-field-simplemask
                    dense
                    label="Destino"
                    outlined
                    name="Destino"
                    v-model="smsNumber"
                    v-bind:properties="{
                      prefix: '',
                      suffix: '',
                      readonly: false,
                      disabled: false,
                      outlined: true,
                      clearable: true,
                      placeholder: '',
                      dense: true,
                    }"
                    v-bind:options="{
                      inputMask: '(##) #####-####',
                      outputMask: '###########',
                      empty: null,
                      applyAfter: false,
                      alphanumeric: true,
                      lowerCase: false,
                    }"
                  ></v-text-field-simplemask>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    dense
                    label="Mensagem"
                    outlined
                    name="Mensagem"
                    v-model="smsMessage"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    block
                    color="blue"
                    :disabled="smsNumber === '' || smsMessage === ''"
                    @click="sendSMS()"
                    >Enviar</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-card>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import AuroraApi from "../../api/aurora";

export default {
  data: () => ({
    smsNumber: "",
    smsMessage: "",
    overlay: false,
  }),
  methods: {
    sendSMS: function () {
      this.overlay = true;
      let user = this.$store.getters["user/currentUser"];
      AuroraApi.sendSMS(
        user.aurora_token,
        user.aurora_campaign_token,
        this.smsNumber,
        this.smsMessage
      )
        .then((response) => {
          this.overlay = false;
          if (response.status === 200) {
            this.smsNumber = "";
            this.smsMessage = "";
            this.$store.dispatch("notification/notify", {
              content: "SMS enviado.",
              color: "light-green lighten-3",
            });
          }
        })
        .catch(function (error) {
          self.$store.dispatch("notification/notify", {
            content: error,
            color: "deep-red lighten-3",
          });
          self.overlay = false;
        });
    },
  },
};
</script>