<template>
  <v-card height="280" min-width="100%">
    <v-card-title>
      <span class="headline"> Pausa </span>
      <v-chip v-if="status == -1" class="ma-2 text-center" label color="blue">
        {{ total_paused_time }}
      </v-chip>
    </v-card-title>
    <v-card-text>
      <v-select
        v-if="status != -1"
        v-model="pauseSelected"
        :items="pauses"
        item-text="description"
        item-value="description"
        filled
        label="Pausa"
      >
      </v-select>
      <v-text-field v-if="status == -1" disabled v-model="currentPause">
      </v-text-field>
    </v-card-text>
    <v-card-actions class="text-center">
      <v-row>
        <v-col cols="12" sm="11" class="pa-0 ml-3">
          <v-btn
            block
            v-if="status != -1"
            color="blue darken-1"
            :disabled="pauseSelected === ''"
            @click="save()"
            >Entrar em pausa</v-btn
          >
          <v-btn block v-if="status == -1" color="blue darken-1" @click="save()"
            >Sair de pausa</v-btn
          >
        </v-col>
        <v-col cols="12" sm="11" class="pa-0 ml-3 my-2">
          <!--v-btn
            block
            color="green darken-1"
            :disabled="pauseSelected === ''"
            @click="saveSchedule()"

          >Agendar em pausa</v-btn-->
        </v-col>
      </v-row>
    </v-card-actions>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import { APIFactory } from "../../api/factory";
import moment from "moment";

const AgentApi = APIFactory.get("agent");
const ListApi = APIFactory.get("list");
export default {
  props: {
    status: Number,
    currentPause: String,
  },
  data: () => ({
    overlay: false,
    pauses: [],
    pauseSelected: "",
    total_paused_time: "00:00:00",
    updateTimeFunc: null,
  }),
  created: function() {
    this.loadData();
  },
  watch: {
    status: function() {
      clearInterval(this.updateTimeFunc);
      if (this.status == -1) {
        // Buscar o tempo inicial
        this.getPauseTime();
      }
    },
  },
  methods: {
    getPauseTime: function() {
      AgentApi.pauseTime().then((response) => {
        this.total_paused_time = response.data.pause_time;
        this.refreshTimes();
      });
    },
    refreshTimes: function() {
      let self = this;
      this.updateTimeFunc = setInterval(function() {
        if (self.status == -1) {
          self.total_paused_time = moment(self.total_paused_time, "HH:mm:ss")
            .add(1, "seconds")
            .format("HH:mm:ss");
        }
      }, 1000);
    },
    save: function() {
      var data;
      if (this.status != -1) {
        data = {
          pause_type: this.pauseSelected,
        };
      } else {
        data = {
          pause_type: this.currentPause,
        };
      }
      this.overlay = true;
      clearInterval(this.updateTimeFunc);
      let self = this;
      AgentApi.pause(data)
        .then((response) => {
          if (response.data.success == 0) {
            this.$store.dispatch("notification/notify", {
              content: "Ação realizada com sucesso.",
              color: "light-green lighten-3",
            });
            this.overlay = false;

            if (this.status != -1) {
              //this.status = -1;
              this.$emit("statusChanged", -1);
            } else {
              //this.status = 1;
              this.$emit("statusChanged", 1);
            }
          } else {
            this.$store.dispatch("notification/notify", {
              content: response.data.msg,
              color: "deep-orange lighten-3",
            });
            this.overlay = false;
          }
        })
        .catch(function(error) {
          self.$store.dispatch("notification/notify", {
            content: error,
            color: "deep-red lighten-3",
          });
          self.overlay = false;
        });
    },
    saveSchedule: function() {
      let data = {
        pause_motive: this.pauseSelected,
      };
      AgentApi.schedule_pause(data).then((response) => {
        if (response.status == 200) {
          this.$store.dispatch("notification/notify", {
            content: "Ação realizada com sucesso.",
            color: "light-green lighten-3",
          });
        } else {
          this.$store.dispatch("notification/notify", {
            content: response.data.msg,
            color: "deep-orange lighten-3",
          });
        }
      });
    },
    loadData: function() {
      ListApi.pauses().then((response) => {
        this.pauses = response.data.pauses;
      });
      if (this.status == -1) {
        this.getPauseTime();
      }
    },
  },
};
</script>
