import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueSweetalert2 from 'vue-sweetalert2';
import store from './store';
import VueMoment from "vue-moment";
import moment from "moment";
import {LoadingPlugin} from "vuetify-loading-overlay";
import './registerServiceWorker'
import "./plugins/vuetify-mask.js";


// axios.defaults.headers['Content-Type'] = 'application/json';
// axios.defaults.headers['Access-Control-Allow-Origin'] = '*';

// Vue.prototype.$http = axios

Vue.config.productionTip = false

moment.locale('pt-BR')

Vue.use(Vuex)
Vue.use(VueSweetalert2);
Vue.use(VueMoment, { moment });
Vue.use(LoadingPlugin, {
  //props
  spinnerProps: {},
  overlayProps: {},
});


new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')