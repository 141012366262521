import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import agent from './modules/agent'
import notification from './modules/notification'
import axiosAPI from '../api/base'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

var store = new Vuex.Store({
  modules: {
    user,
    agent,
    notification
  },
  strict: debug,
  //plugins: [vuexStorage.plugin]
});

// Add a 401 response interceptor
axiosAPI.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
    console.log(error.response)
    if (user.getters.loggedIn) {
      Vue.swal({
        title: "Atenção",
        text: error.response.data.error,
        icon: 'error'
      }).then(() => { 
        store.dispatch('user/doLogout')
      });
    } else {
      return Promise.reject(error);
    }    
  } else {
      return Promise.reject(error);
  }
});

export default store;