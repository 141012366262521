<template>
  <v-dialog v-model="showDialog" max-width="1024" persistent>
    <v-tabs v-model="tab">
      <v-tabs-slider color="orange darken-3"></v-tabs-slider>
      <v-tab key="resume">
        <v-icon>mdi-poll</v-icon>
        Resumo
      </v-tab>
      <v-tab key="evaluate">
        <v-icon>mdi-certificate</v-icon>
        Avaliação
      </v-tab>
      <v-spacer></v-spacer>
      <v-btn class="pa-6" icon small @click="hide()">
        <v-icon small>mdi-close-circle</v-icon>
      </v-btn>

      <v-tabs-items v-model="tab">
        <v-tab-item key="resume">
          <v-card height="520" width="1024">
            <v-card-text>
              <v-row>
                <v-chip v-for="item in resume_items" :key="item.key" class="ma-1 text-center" x-large label
                  :color="item.color">
                  {{ item.text }}<br>{{ item.value }}
                </v-chip>
              </v-row>

              <v-simple-table fixed-header>
                <tbody>
                  <tr>
                    <td>Status</td>
                    <td>
                      <v-chip class="ma-2 text-center" large :color="status_color">
                        {{ status_text }}
                      </v-chip>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">Tempo logado</td>
                    <td>{{ details.total_loged_time }}</td>
                  </tr>
                  <tr>
                    <td class="text-left">Tempo pausado</td>
                    <td>{{ details.total_paused_time }}</td>
                  </tr>
                  <tr>
                    <td class="text-left">Tempo ocioso</td>
                    <td>{{ details.total_lazy_time }}</td>
                  </tr>
                  <tr>
                    <td class="text-left">Pausa atual</td>
                    <td></td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item key="evaluate">
          <EvaluateModal />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import EvaluateModal from './EvaluateModal.vue';


export default {
  props: {
    showDialog: Boolean,
    overlay: Boolean,
  },
  data: () => ({
    tab: null,
  }),
  created: function () {
    this.loadData();
  },
  methods: {
    hide: function () {
      this.showDialog = false;
      this.$emit("onHide");
    },
  },
  computed: {
    details: function () {
      return this.$store.getters["agent/details"];
    },
    resume_items: function () {
      return this.$store.getters["agent/resume"];
    },
    status_color: function () {
      let det = this.$store.getters["agent/details"];
      switch (det.status) {
        case 2:
          return "red accent-4";
        case -1:
          return "blue";
        case 5:
          return "orange lighten-2";
        case 1:
          return "green accent-4";
        default:
          return "grey lighten-1";
      }
    },
    status_text: function () {
      let det = this.$store.getters["agent/details"];
      switch (det.status) {
        case 2:
          return "OCUPADO";
        case -1:
          return "EM PAUSA";
        case 5:
          return "CHAMANDO";
        case 1:
          return "LIVRE";
        default:
          return "OFFLINE";
      }
    }
  },
  components: { EvaluateModal }
}
</script>