<template>
  <v-sheet class="px-4" rounded color="#F2F2F2">
    <v-row v-if="user.show_calldata === true">
      <v-col xs="12" sm="12" md="9">
        <v-card height="450">
          <v-card-title>
            Chamada Atual

            <v-chip class="ma-2 text-center" label :color="status_color">
              {{ status_text
              }}<span v-if="status == 2"
                >&nbsp;/{{ current_call.duration }}</span
              >
            </v-chip>

            <v-chip
              v-if="current_call.campaign_item_id !== 0"
              class="ma-2 text-center"
              label
              color="pink"
            >
              {{ current_call.campaign_name }}
            </v-chip>

            <v-spacer />
            <v-btn
              class="d-flex d-sm-none"
              large
              color="blue"
              @click="openPauseModal = true"
            >
              Pausa
            </v-btn>
            <v-btn
              :readonly="disableSave()"
              color="primary"
              @click="updateData()"
            >
              Salvar
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-row dense>
                <v-col cols="4">
                  <v-text-field
                    label="Protocolo"
                    outlined
                    name="protocol"
                    v-model="current_call.protocol"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    label="Cliente"
                    outlined
                    name="client"
                    v-model="current_call.client_name"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="4">
                  <v-text-field
                    label="CPF/CNPJ"
                    outlined
                    name="document"
                    v-model="current_call.cpf_cnpj"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select
                    :items="call_motives"
                    item-text="description"
                    item-value="description"
                    label="Motivo da ligação"
                    outlined
                    name="call_motive"
                    v-model="current_call.call_motive"
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Outras informações"
                    outlined
                    name="other_infos"
                    v-model="current_call.extra_info"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <v-textarea
                    label="Observação"
                    rows="5"
                    outlined
                    name="obs"
                    v-model="current_call.obs"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <PauseModal
            :showDialog="openPauseModal"
            :status="status"
            :currentPause="pauseType"
            @onHide="openPauseModal = false"
          />
        </v-card>
      </v-col>
      <v-col md="3" class="d-none d-sm-flex">
        <v-row no-gutters>
          <v-col cols="12">
            <Pause :status="status" :currentPause="pauseType" />
          </v-col>
          <v-col cols="12" class="mt-2">
            <Schedule :campaignItemId="current_call.campaign_item_id" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col xs="12" sm="12" md="3">
        <Pause
          :status="status"
          :currentPause="pauseType"
          @statusChanged="updatePauseStatus"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { APIFactory } from "../../api/factory";
import { EventHub } from "../../utils/eventHub";
import PauseModal from "../PauseModal";
import Pause from "./Pause";
import Schedule from "./Schedule";
import moment from "moment";

const AgentApi = APIFactory.get("agent");
const CallApi = APIFactory.get("call");
export default {
  props: {
    call_motives: Array,
  },
  components: {
    PauseModal,
    Pause,
    Schedule,
  },
  data: () => ({
    status_color: "grey lighten-1",
    status_text: "Offline",
    status: 0,
    current_call: {
      id: 0,
      protocol: "",
      client_name: "",
      cpf_cnpj: "",
      extra_info: "",
      obs: "",
      call_motive: "",
      duration: "00:00:00",
      is_campaign: false,
      campaign_item_id: 0,
      campaign_name: "",
    },
    pauseType: "",
    openPauseModal: false,
    updateTimeFunc: null,
  }),
  created: function() {
    this.loadData();
    this.initialStatus();
    EventHub.$on("refresh-data", (e) => {
      let last_status = this.status;
      clearInterval(this.updateTimeFunc);
      this.current_call.duration = "00:00:00";
      let data = JSON.parse(e.data);
      this.updateStatus(data.status);
      this.pauseType = data.pause_type;
      if (
        (last_status !== 2 || last_status !== 5) &&
        (data.status === 2 || data.status === 5)
      ) {
        this.current_call = {
          id: 0,
          protocol: "",
          client_name: "",
          cpf_cnpj: "",
          extra_info: "",
          obs: "",
          call_motive: "",
          duration: "00:00:00",
          is_campaign: false,
          campaign_item_id: 0,
          campaign_name: "",
        };
        this.updateData();
      }
    });
  },
  watch: {},
  methods: {
    updatePauseStatus: function(status) {
      this.status = status;
    },
    disableSave: function() {
      return this.current_call.id === 0;
    },
    refreshTimes: function() {
      let self = this;
      this.updateTimeFunc = setInterval(function() {
        if (self.status == 2) {
          self.current_call.duration = moment(
            self.current_call.duration,
            "HH:mm:ss"
          )
            .add(1, "seconds")
            .format("HH:mm:ss");
        }
      }, 1000);
    },
    updateData: function() {
      if (this.current_call.id !== 0) {
        CallApi.update(this.current_call.id, this.current_call).then(
          (response) => {
            if (response.status == 200) {
              this.loadData();
            }
          }
        );
      } else {
        this.loadData();
      }
    },
    loadData: function() {
      AgentApi.current_call().then((response) => {
        if (response.data.id !== this.current_call.id) {
          this.current_call = response.data;
        }
      });
    },
    initialStatus: function() {
      AgentApi.details().then((response) => {
        this.updateStatus(response.data.status);
        this.pauseType = response.data.type_pause;
      });
    },
    updateStatus: function(status) {
      this.status = status;
      switch (status) {
        case 2:
          this.status_color = "red accent-4";
          this.status_text = "Ocupado";
          this.refreshTimes();
          break;
        case -1:
          this.status_color = "blue";
          this.status_text = "Em pausa";
          break;
        case 5:
          this.status_color = "orange lighten-2";
          this.status_text = "Chamando";
          break;
        case 1:
          this.status_color = "green accent-4";
          this.status_text = "Livre";
          break;
        default:
          this.status_color = "grey lighten-1";
          this.status_text = "Offline";
      }
    },
  },
  computed: {
    user: function() {
      return this.$store.getters["user/currentUser"];
    },
  },
};
</script>
