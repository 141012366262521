import base from "./base";

const resource = "/calls";

export default {
  update(id, data) {
    return base.put(`${resource}/${id}`, data)
  },
  reschedule(id, data) {
    return base.put(`${resource}/${id}/reschedule`, data)
  },
}