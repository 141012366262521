import base from "./base";

const resource = "/lists";

export default {
  call_motives() {
    return base.get(`${resource}/call_motives`)
  },
  pauses() {
    return base.get(`${resource}/pause_types`)
  },
}