<template>
  <v-sheet
    class="pa-4"
    rounded
    color="#F2F2F2"
  >
    <v-card
      height="550"
    >
      <v-card-title>Últimas Chamadas</v-card-title>
      <v-card-text>
        <v-simple-table dense height="480px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Protocolo
                </th>
                <th class="text-left">
                  Data/Hora
                </th>
                <th class="text-left">
                  Status
                </th>
                <th class="text-left">
                  Cliente
                </th>
                <th class="text-left">
                  Outras informações
                </th>
                <th class="text-left">
                  Motivo
                </th>
                <th class="text-left">
                  Ações
                </th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="item in last_calls"
              >
                <tr :key="item.id">
                  <td>{{ item.protocol }}</td>
                  <td>{{ item.call_datetime }}</td>
                  <td>{{ statusText(item.status) }}</td>
                  <td>{{ item.client_name }}</td>
                  <td>{{ item.client_extra_info }}</td>
                  <td>
                    <v-select
                      :items="call_motives"
                      item-text="description"
                      item-value="description"
                      name="call_motive"
                      v-model="item.call_motive"
                      @change="updateCallMotive(item.id, item)"
                      
                    ></v-select>
                  </td>
                  <td>
                    <v-btn
                      @click="editCall(item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr :key="item.id">
                  <td colspan="6"><b>Obs:</b> {{item.call_obs}}</td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-snackbar
        v-model="snackbar"
        timeout="5000"
      >
        {{ snack_message }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <CallModal :showDialog="openCallModal" :call_motives="call_motives" :call="selectedCall" @onHide="openCallModal=false"/>
    </v-card>
  </v-sheet>
</template>

<script>
import { APIFactory } from '../../api/factory'
import { EventHub } from '../../utils/eventHub'
import CallModal from '../CallModal'

const AgentApi = APIFactory.get('agent')
const CallApi = APIFactory.get('call')
export default {
  props: {
    call_motives: Array
  },
  components: {
    CallModal
  },
  data: () => ({
    openCallModal: false,
    snackbar: false,
    snack_message: '',
    last_calls: [],
    selectedCall: {}
  }),
  created: function () {
    this.loadData()
    EventHub.$on('refresh-data', () => {
      this.loadData()
    });
  },
  methods: {
    editCall: function(call) {
      this.selectedCall = call
      this.openCallModal = true
    },
    loadData: function() {
      AgentApi.last_calls().then(response => {
        this.last_calls = response.data        
      })
    },
    updateCallMotive: function(id, data) {
      CallApi.update(id, data).then(response => {
        if (response.status == 200) {
          this.snackbar = true
          this.snack_message = 'Atualizado com sucesso.'
        }
      });
    },
    statusText: function(status) {
      switch (status) {
        case 'ANSWERED':
          return 'Atendida'
        case 'NOT ANSWERED':
          return 'Não atendida'
        case 'NOT ANSWERED AGENT':
          return 'Não atendida agente'
        case 'BUSY':
          return 'Ocupado'
        default:
          return '--'
      }
    }
  },
}
</script>