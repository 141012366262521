var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"217","min-width":"100%"}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" Reagendar chamada ")])]),_c('v-card-text',[_c('v-text-field-simplemask',{attrs:{"disabled":this.campaignItemId !== 0,"options":{
      inputMask: '##/##/#### ##:##:##',
      outputMask: '##/##/#### ##:##:##',
      empty: null,
      applyAfter: false,
      alphanumeric: false,
      lowerCase: false,
    }},model:{value:(_vm.nextCallDate),callback:function ($$v) {_vm.nextCallDate=$$v},expression:"nextCallDate"}})],1),_c('v-card-actions',{staticClass:"text-center"},[(_vm.campaign_item_id !== 0)?_c('v-btn',{attrs:{"block":"","color":"pink","disabled":this.campaignItemId === 0},on:{"click":function($event){return _vm.save()}}},[_vm._v("Reagendar")]):_vm._e()],1),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }