import Vue from 'vue'
import VueRouter from 'vue-router'
import Attendance from '../views/Attendance.vue'
import Login from '../views/Login.vue'
import store from '../store'
import AppLayout from '@/layouts/AppLayout.vue'
import PublicLayout from '@/layouts/PublicLayout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: PublicLayout,
    children: [
      {
        path: '',
        component: Login
      },
      {
        path: '/login',
        component: Login
      }
    ]
  },
  {
    path: '/app',
    component: AppLayout,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        component: Attendance
      },
      {
        path: 'attendance',
        component: Attendance
      }
    ]
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
      let loggedIn = store.getters['user/loggedIn']
      if (loggedIn) {
          next()
          return
      }
      next('/login')
  } else {
      next()
  }
})

export default router
