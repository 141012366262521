<template>
  <v-card
    height="217"
    min-width="100%"
  >
    <v-card-title>
      <span class="headline"> Reagendar chamada </span>
    </v-card-title>
    <v-card-text>
     <v-text-field-simplemask
       v-model="nextCallDate"
       :disabled="this.campaignItemId !== 0"
       v-bind:options="{
        inputMask: '##/##/#### ##:##:##',
        outputMask: '##/##/#### ##:##:##',
        empty: null,
        applyAfter: false,
        alphanumeric: false,
        lowerCase: false,
      }"
     >
     </v-text-field-simplemask>
    </v-card-text>
    <v-card-actions class="text-center">
      <v-btn block v-if="campaign_item_id !== 0" color="pink" @click="save()" :disabled="this.campaignItemId === 0">Reagendar</v-btn>
    </v-card-actions>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay> 
  </v-card>
</template>

<script>
import { APIFactory } from '../../api/factory'

const CallApi = APIFactory.get('call')

export default {
  props: {
    campaignItemId: Number
  },
  data: () => ({
    overlay: false,
    nextCallDate: ''
  }),
  methods: {
    save: function(){
      let data = {
        next_call_date: this.nextCallDate
      }
      CallApi.reschedule(this.campaignItemId, data).then(response => {
        if (response.status == 200) {
          this.$store.dispatch('notification/notify', { content:'Ação realizada com sucesso.', color: 'light-green lighten-3' })
        } else {
          this.$store.dispatch('notification/notify', { content: response.data.msg, color:'deep-orange lighten-3' })
        }
      })
    }
  }
}
</script>