<template>
  <v-dialog
    v-model="showDialog"
    max-width="400"
    persistent
  >
    <v-card>
      <v-card-title>
        <span class="headline"> Pausa </span>
      </v-card-title>
      <v-card-text>
        <v-select v-if="status != -1"
          v-model="pauseSelected"
          :items="pauses"
          item-text="description"
          item-value="description"
          filled
          label="Pausa"
        >
        </v-select>
        <v-text-field v-if="status == -1"
          disabled
          v-model="currentPause"
        >
        </v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="hide()">Fechar</v-btn>
        <v-btn v-if="status != -1" color="blue darken-1" text @click="save()">Entrar em pausa</v-btn>
        <v-btn v-if="status == -1" color="blue darken-1" text @click="save()">Sair de pausa</v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>  
  </v-dialog>
</template>

<script>
import { APIFactory } from '../api/factory'
const AgentApi = APIFactory.get('agent')
const ListApi = APIFactory.get('list')
export default {
  props: {
    showDialog: Boolean,
    status: Number,
    currentPause: String
  },
  data: () => ({
    overlay: false,
    pauses: [],
    pauseSelected: '',
  }),
  created: function () {
    this.loadData()
  },
  methods: {
    hide: function() {
      this.showDialog = false
      this.$emit("onHide");
    },
    save: function() {
      var data
      if (this.status != -1){
        data = {
          pause_type: this.pauseSelected
        }
      } else {
        data = {
          pause_type: this.currentPause
        }
      }
      this.overlay = true
      AgentApi.pause(data).then(response => {        
        if (response.status == 200) {
          this.hide()
          this.$store.dispatch('notification/notify', { content:'Ação realizada com sucesso.', color: 'light-green lighten-3' })
          this.overlay = false
        } else {
          this.$store.dispatch('notification/notify', { content: response.data.msg, color:'deep-orange lighten-3' })
          this.overlay = false
        }
      }) 
      
    },
    loadData: function() {
      ListApi.pauses().then(response => {
        this.pauses = response.data.pauses
      })
    }
  }
}
</script>