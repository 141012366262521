<template>
  <v-card height="520" width="1024">
    <v-btn @click="listEvaluates">Atualizar</v-btn>
    <v-card-text>
      <v-simple-table dense>
        <thead>
          <tr>
            <th class="text-center">Data</th>
            <th>P1</th>
            <th>P2</th>
            <th>P3</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="item in evaluates">
            <tr :key="item.id">
              <td class="text-center">
                {{ getFormattedDate(item.survey_date) }}
              </td>
              <td>{{ item.answers.split(";")[1] }}</td>
              <td>{{ item.answers.split(";")[2] }}</td>
              <td>{{ item.answers.split(";")[3] }}</td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { APIFactory } from "../api/factory";
import moment from "moment";

const AgentApi = APIFactory.get("agent");
export default {
  data: () => ({
    evaluates: [],
  }),
  created: function() {
    this.listEvaluates();
  },
  methods: {
    listEvaluates: function() {
      AgentApi.evaluates().then((response) => {
        this.evaluates = response.data;
      });
    },
    getFormattedDate(date) {
      return moment(date).format("DD/MM/Y");
    },
  },
};
</script>

<style></style>
