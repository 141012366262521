<template>
  <v-app-bar app>
    <img class="mr-3" :src="require('@/assets/logo_beatrix.png')" height="40" />

    <v-spacer></v-spacer>

    <Agent />
  </v-app-bar>
</template>

<script>
import Agent from "../../components/Agent";
export default {
  components: {
    Agent,
  },
  methods: {
    toogleMenu: function () {
      this.$emit("toogle-menu-event");
    },
  },
};
</script>