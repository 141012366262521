<template>
  <v-app>
    <Header />
    <Notification />
    <v-content>
      <router-view></router-view>
    </v-content>
  
  </v-app>
</template>

<script>
import Header from './public/Header'
import Notification from '../components/Notification'
export default {
  components: {
    Header,
    Notification
  }
}
</script>

<style scoped>
.theme--light.v-application {
  background-color: #63707C;
}
</style>
