//import { APIFactory } from "../../api/factory"
import router from "../../router";
import axiosAPI from "../../api/base";
//const AgentApi = APIFactory.get('agent')

if (
  localStorage.getItem("agent-token") != null &&
  localStorage.getItem("agent-token") !== undefined
) {
  const token = JSON.parse(localStorage.getItem("agent-token"));
  axiosAPI.defaults.headers.common["Authorization"] = token;
} else {
  axiosAPI.defaults.headers.common["Authorization"] = "";
}

const state = () => ({
  token: JSON.parse(localStorage.getItem("agent-token")) || undefined,
  currentUser: JSON.parse(localStorage.getItem("agent-user")) || undefined,
});

const getters = {
  loggedIn: (state) => !!state.currentUser,
  currentUser: (state) => state.currentUser,
  token: (state) => state.token,
};

const actions = {
  authenticate({ commit }, response) {
    commit("login", response);
  },
  doLogin({ commit }, config) {
    commit("queueAndExtension", config);
  },
  doLogout({ commit }) {
    commit("logout");
    router.push("/");
  },
};

const mutations = {
  login(state, userData) {
    state.currentUser = userData.data;
    state.token = userData.headers.authorization;
    axiosAPI.defaults.headers.common["Authorization"] =
      userData.headers.authorization;

    localStorage.setItem("agent-user", JSON.stringify(state.currentUser));
    localStorage.setItem("agent-token", JSON.stringify(state.token));
  },
  queueAndExtension(state, data) {
    state.currentUser.queues = data.logged_queues;
    state.currentUser.extension = data.logged_extension;
    localStorage.setItem("agent-user", JSON.stringify(state.currentUser));
  },
  logout(state) {
    state.currentUser = "";
    state.token = "";
    localStorage.removeItem("agent-user");
    localStorage.removeItem("agent-token");
    axiosAPI.defaults.headers.common["Authorization"] = "";
  },
  updateUserData(state, userData) {
    state.currentUser = userData.data;
    localStorage.setItem("agent-user", JSON.stringify(state.currentUser));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
