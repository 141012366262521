const state = () => ({
  content: '',
  color: ''
})

const getters = {}
const actions = {
  notify( {commit}, payload) {
    commit('showMessage', payload)
  }
}

const mutations = {
  showMessage (state, payload) {
    state.content = payload.content
    state.color = payload.color
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}