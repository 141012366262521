<template>
  <v-snackbar
    v-model="show"
    :color="color"
    timeout="5000"
  >
    {{ message }}

    <v-btn text @click="show = false">Fechar</v-btn>
  </v-snackbar>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      message: '',
      color: ''
    }
  },

  created () {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'notification/showMessage') {
        console.log('Notificação.......')
        this.message = state.notification.content
        this.color = state.notification.color
        this.show = true
      }
    })
  }
}
</script>