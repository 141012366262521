<template>
  <v-container style="padding-top: 100px">
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="6" class="d-none d-sm-none d-md-flex">
        <v-card height="330px" class="rounded-l-lg rounded-r-0">
          <v-img
            height="330px"
            :src="require('@/assets/hero_1.png')"
            class="white--text"
          ></v-img>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-card height="330px" class="rounded-r-lg rounded-l-0">
          <v-card-title>
            <div>
              <h3 class="headline text--accent-2">Entrar</h3>
            </div>
          </v-card-title>
          <v-card-text>
            <v-form v-if="user_auth === false">
              <v-text-field
                label="Agente"
                name="login"
                prepend-icon="mdi-account"
                type="text"
                v-model="user.code"
              ></v-text-field>

              <v-text-field
                id="password"
                label="Password"
                name="password"
                prepend-icon="mdi-lock"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
                v-model="user.password"
                @keydown.enter="doLogin()"
              ></v-text-field>
              <v-btn
                block
                x-large
                color="primary title"
                @click="doLogin()"
                :loading="btn1Loading"
                :disabled="btn1Loading"
              >
                VALIDAR AGENTE
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-form>

            <v-form v-if="user_auth === true">
              <v-select
                v-model="user.extension"
                :items="extensions"
                item-text="extension"
                item-value="extension"
                filled
                label="Ramal"
              ></v-select>
              <v-select
                v-model="user.queue"
                :items="queues"
                item-text="description"
                item-value="name"
                filled
                label="Fila"
                multiple
              ></v-select>
              <v-btn
                block
                x-large
                color="primary title"
                @click="doLogInQueue()"
              >
                ENTRAR
                <v-overlay :value="overlay">
                  <v-progress-circular
                    indeterminate
                    size="64"
                  ></v-progress-circular>
                </v-overlay>
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { APIFactory } from "../api/factory";
const AuthApi = APIFactory.get("auth");
const AgentApi = APIFactory.get("agent");

export default {
  data: () => ({
    show1: false,
    user_auth: false,
    btn1Loading: false,
    overlay: false,
    user: {
      code: "",
      password: "",
      extension: "",
      queue: "",
    },
    queues: [],
    extensions: [],
  }),
  methods: {
    doLogInQueue: function() {
      let config = {
        config: {
          queues: this.user.queue,
          extension: this.user.extension,
        },
      };
      this.overlay = true;
      AgentApi.loginQueue(config)
        .then((response) => {
          if (response.data["status"] === 0) {
            this.$store.dispatch("user/doLogin", response.data);
            this.$router.push("/app/attendance");
            this.overlay = false;
          } else {
            this.overlay = false;
            this.$swal({
              title: "Atenção",
              text: response.data.message,
              icon: "error",
            });
          }
        })
        .catch(function(error) {
          this.overlay = false;
          console.log(error);
        });
    },
    doLogin: function() {
      this.btn1Loading = true;
      let user = {
        agent_user: {
          code: this.user.code,
          password: this.user.password,
        },
      };
      //
      let self = this;
      AuthApi.login(user)
        .then((response) => {
          //let user = response.data
          console.log(response);
          if (response) {
            this.$store.dispatch("user/authenticate", response);
            AgentApi.my_extensions()
              .then((response) => {
                self.extensions = response.data["extensions"];
                AgentApi.my_queues()
                  .then((response) => {
                    self.queues = response.data["queues"];
                    self.user_auth = true;
                    this.btn1Loading = false;
                  })
                  .catch(function(error) {
                    console.log(error);
                    this.btn1Loading = false;
                  });
              })
              .catch(function(error) {
                console.log(error);
                this.btn1Loading = false;
              });
          } else {
            this.btn1Loading = false;
            this.$swal({
              title: "Atenção",
              text: "Usuário ou senha inválido",
              icon: "error",
            });
          }
        })
        .catch(function(error) {
          self.btn1Loading = false;
          console.log(error);
        });
    },
  },
};
</script>
