import auth from "./auth";
import agent from "./agent";
import list from "./list";
import call from "./call";

const repositories = {
  auth: auth,
  agent: agent,
  list: list,
  call: call
};

export const APIFactory = {
  get: name => repositories[name] 
}