import base from "./base";

const resource = "/login";
const logout_resource = "/logout";

export default {
  login(data) {
    base.defaults.headers.common["Authorization"] = ""
    return base.post(`${resource}`, data)
  },
  logout() {
    return base.delete(`${logout_resource}.json`)
  }
}