<template>
  <v-app id="inspire">
    <TopBar v-on:toogle-menu-event="toogleMenu()"/>
      <v-content>
        <v-container
          fluid
        >
          <router-view></router-view>
        </v-container>
      </v-content>
      <Notification />
    <Footer />
  </v-app>
</template>

<script>
  import TopBar from './app/TopBar';
  import Footer from './app/Footer'
  import Notification from '../components/Notification'

  export default {
    data: () => ({
      drawer: false
    }),
    components: {
      TopBar,
      Footer,
      Notification
    },
    methods: {
      toogleMenu: function() {
        this.drawer = !this.drawer;
      },
    }
    
  }
</script>


<style scoped>
.theme--light.v-application {
  background-color: #63707C;
}
</style>
