const state = () => ({
  details: {
    status: 0,
    total_loged_time: '',
    total_paused_time: '',
    total_lazy_time: '',
    time: '00:00:00'
  },
  resume: []
})

const getters = {
  details: state => state.details,
  resume: state => state.resume
}

const actions = {
  detailsData({commit}, response) {
    commit('setDetails', response)
  },
  resumeData({commit}, response) {
    commit('setResume', response)
  }
}

const mutations = {
  setDetails(state, response) {
    state.details.status = response.data.status
    state.details.total_loged_time = response.data.total_loged_time
    state.details.total_paused_time = response.data.total_paused_time
    state.details.total_lazy_time = response.data.total_lazy_time
  },
  setResume(state, response) {    
    state.resume = []
    state.resume.push({
      key: 'answered',
      text: 'Atendidas',
      value: response.data.answered,
      color: 'light-green lighten-3'
    })
    state.resume.push({
      key: 'not_answered',
      text: 'Não atendidas',
      value: response.data.not_answered,
      color: 'red accent-4'
    })
    state.resume.push({
      key: 'busy',
      text: 'Ocupadas',
      value: response.data.busy,
      color: 'yellow lighten-2'
    })
    state.resume.push({
      key: 'wait',
      text: 'Espera média',
      value: response.data.wait,
      color: 'amber lighten-2'
    })
    state.resume.push({
      key: 'duration_answered',
      text: 'Duração média',
      value: response.data.duration_answered,
      color: 'light-green lighten-3'
    })
    state.resume.push({
      key: 'duration_not_answered',
      text: 'Duração média',
      value: response.data.duration_not_answered,
      color: 'red accent-4'
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}