import Vue from 'vue';
import Vuetify from 'vuetify/lib';


Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true
    },
    themes: {
      dark: {
        primary: '#2E3841',
        accent: '#FF4081',
        secondary: '#ffe18d',
        success: '#4CAF50',
        info: '#2196F3',
        warning: '#FB8C00',
        error: '#FF5252'
      },
      light: {
        primary: '#2E3841',
        accent: '#E91E63',
        secondary: '#F69640',
        success: '#4CAF50',
        info: '#E57512',
        warning: '#FB8C00',
        error: '#FF5252'
      }
    }
  }
});
