<template>
  <v-dialog
    v-model="showDialog"
    max-width="400"
  >
    <v-card>
      <v-card-title>
        <span class="headline"> Fila </span>
      </v-card-title>
      <v-card-text>
        <v-select
          v-model="queueSelected"
          :items="queues"
          item-text="name"
          item-value="name"
          filled
          label="Fila"
        >
        </v-select>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="hide()">Fechar</v-btn>
        <v-btn color="blue darken-1" text @click="save()">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { APIFactory } from '../api/factory'
const AgentApi = APIFactory.get('agent')
export default {
  props: {
    showDialog: Boolean
  },
  data: () => ({
    queues: [],
    queueSelected: ''
  }),
  created: function () {
    this.loadData()
  },
  methods: {
    hide: function() {
      this.showDialog = false
      this.$emit("onHide");
    },
    save: function() {
      let data = {
        queue: this.queueSelected
      }
      AgentApi.change_queue(data).then(response => {
        if (response.status == 200) {
          this.showDialog = false;
        }
      }) 
      
    },
    loadData: function() {
      AgentApi.my_queues().then(response => {
        this.queues = response.data['queues']
      })
    }
  }
}
</script>